<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <v-row class="mt-0 mt-md-4 px-1" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">카테고리 관리</v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-1">
                    <v-toolbar color="app-bar lighten-1" dense>
                        <v-toolbar-title class="white--text subtitle-2">카테고리</v-toolbar-title>

                        <v-spacer></v-spacer>
                        <v-btn color="accent" fab dark small absolute bottom right @click="$refs.categoryDialog.open()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-draggable-treeview v-if="categories.length" v-model="categories" item-key="_id" children="children" group="categories" @click="item => category = item" @input="sort().then(search)" dense></v-draggable-treeview>
                        <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                    </v-card-text>

                </v-card>
            </v-col>

            <v-col cols="12" md="6">
                <v-card :disabled="!category?._id" class="mx-1">
                    <v-card-title class="tit tit--xs">카테고리 기본정보</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="category._id" label="_id" persistent-placeholder hide-details disabled></v-text-field>
                        <v-text-field v-model="category.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                        <v-text-field v-model="category.code" label="카테고리 코드(영문)" persistent-placeholder hide-details class="mt-4"></v-text-field>
                    </v-card-text>
                </v-card>

                <v-row class="mt-0 px-1">
                    <v-col md="auto">
                        <v-btn color="grey darken-1" class="white--text" @click="remove">삭제</v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col md="auto">
                        <v-btn color="primary">저장</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <category-dialog ref="categoryDialog" @save="search"></category-dialog>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import CategoryDialog from "@/components/console/carbon/categories/category-dialog.vue";

export default {
    components: {
        NaverSmarteditor,
        CategoryDialog
    },
    data(){
        return {
            ready: false,

            category: {},
            categories: []
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            this.search();
            if(this.category._id){
                let { category } = await api.console.carbon.categories.get(this.category);
                this.category = category;
            }
            this.ready = true;
        },
        async search(){
            let { categories } = await api.console.carbon.categories.gets({
                headers: { limit: 0 },
                params: { depth: 1 }
            });

            this.categories = categories;
            this.$refs.categoryDialog.init();
        },
        async save(){
            this.category._id ? await api.console.carbon.categories.put() : await api.console.carbon.categories.post();
            alert("저장되었습니다");
            this.$router.go(-1);
        },
        async remove(){
            await api.console.carbon.categories.delete(this.category);
            alert("삭제되었습니다");
            this.search();
        },
        async sort(categories = this.categories, depth = 1, _parents = []){
            for(let category of categories){
                await api.console.carbon.categories.put({
                    ...category,
                    depth,
                    _children: category.children.map(child => child._id),
                    _parents
                });
                if(category.children) this.sort(category.children, depth + 1, [...category._parents, category._id ]);
            }
        },
    },
}
</script>