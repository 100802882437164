var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._course ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("코스 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("코스 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.name,
      callback: function ($$v) {
        _vm.$set(_vm.course, "name", $$v);
      },
      expression: "course.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "응모기준",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.applicationCriteria,
      callback: function ($$v) {
        _vm.$set(_vm.course, "applicationCriteria", $$v);
      },
      expression: "course.applicationCriteria"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-color-picker', {
    attrs: {
      "dot-size": "25",
      "swatches-max-height": "200",
      "mode": "hexa"
    },
    model: {
      value: _vm.course.color,
      callback: function ($$v) {
        _vm.$set(_vm.course, "color", $$v);
      },
      expression: "course.color"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("지점정보")]), _c('v-card-text', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.course.waypoints.push({
          name: null,
          latitude: null,
          longitude: null
        });
      }
    }
  }, [_vm._v("지점추가")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("지점삭제")])], 1), _c('v-card-text', _vm._l(_vm.course.waypoints, function (waypoint, index) {
    return _c('v-row', {
      key: index,
      staticClass: "mt-3"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "이름",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.name,
        callback: function ($$v) {
          _vm.$set(waypoint, "name", $$v);
        },
        expression: "waypoint.name"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "위도",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.latitude,
        callback: function ($$v) {
          _vm.$set(waypoint, "latitude", $$v);
        },
        expression: "waypoint.latitude"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "경도",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.longitude,
        callback: function ($$v) {
          _vm.$set(waypoint, "longitude", $$v);
        },
        expression: "waypoint.longitude"
      }
    })], 1)], 1);
  }), 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }