<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._course" class="headline text-start mt-8">코스 등록</div>
            <div v-else class="headline text-start mt-8">코스 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="course.name" label="제목" persistent-placeholder hide-details></v-text-field>
                            <v-text-field v-model="course.applicationCriteria" class="mt-5" label="응모기준" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-color-picker v-model="course.color" dot-size="25" swatches-max-height="200" mode="hexa" />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">지점정보</v-card-title>
                <v-card-text>
                    <v-btn color="primary" @click="course.waypoints.push({ name: null, latitude: null, longitude: null })">지점추가</v-btn>
                    <v-btn color="primary">지점삭제</v-btn>
                </v-card-text>
                <v-card-text>
                    <v-row v-for="waypoint, index in course.waypoints" :key="index" class="mt-3">
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.name" label="이름" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.latitude" label="위도" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.longitude" label="경도" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        VDatetimeField,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 코스 정보
            ///////////////////////////////////////////////
            course: {
                name: null,
                color: null,
                applicationCriteria: null,
                waypoints: [
                    { name: null, latitude: null, longitude: null },
                ]
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {

                if (this.$route.params._course) {
                    var { course } = await api.console.courses.get({ _id: this.$route.params._course });
                    this.course = course;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { course } = this.course._id ? await api.console.courses.put(this.course) : await api.console.courses.post(this.course)

                alert(this.course._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

    },
};
</script>
