var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "tit tit--lg",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.order._id ? _c('span', [_vm._v("주문상세")]) : _vm._e()]), _c('v-spacer')], 1), _c('v-card', [_c('v-card-title', [_vm._v(" 상품 정보 ")]), _c('v-card-text', [_c('v-data-table', {
    staticClass: "mt-2 mx-1 elevation-1",
    attrs: {
      "items": [_vm.order.product],
      "item-key": "_id",
      "headers": _vm.productHeaders,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-pagination": ""
    },
    scopedSlots: _vm._u([{
      key: `item.thumb`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-img', {
          staticClass: "ma-auto",
          attrs: {
            "src": item.thumb.src,
            "max-width": "96",
            "height": "96",
            "contain": ""
          }
        })];
      }
    }, {
      key: `item.price`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.price.format()) + " 원 ")];
      }
    }], null, true)
  }), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "red",
      "disabled": _vm.order.isRefund
    },
    on: {
      "click": _vm.refund
    }
  }, [_vm._v("마일리지 환불")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_vm._v(" 주문정보 ")]), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "주문번호",
      "readonly": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.order.orderNo,
      callback: function ($$v) {
        _vm.$set(_vm.order, "orderNo", $$v);
      },
      expression: "order.orderNo"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.$dayjs(_vm.order.createdAt).format('YYYY-MM-DD HH:mm:ss'),
      "label": "주문일",
      "readonly": "",
      "persistent-placeholder": "",
      "hide-details": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "회원 이름",
      "readonly": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.order.user.username,
      callback: function ($$v) {
        _vm.$set(_vm.order.user, "username", $$v);
      },
      expression: "order.user.username"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "value": _vm.order.user.phone.phoneNumberFormat(),
      "label": "회원 연락처",
      "readonly": "",
      "persistent-placeholder": "",
      "hide-details": ""
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.statusItems,
      "label": "상태",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.order.status,
      callback: function ($$v) {
        _vm.$set(_vm.order, "status", $$v);
      },
      expression: "order.status"
    }
  })], 1), _c('v-col')], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }