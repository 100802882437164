<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">완주 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="응모일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="응모일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-3">
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter._course" :items="couresItems" label="코스" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                </v-col>
            </v-row>

            <v-data-table :items="completions" :headers="completionsHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+completions.indexOf(item) + 1) }}
                </template>
                <template #[`item.user.phone`]="{ item }">
                    {{ item.user.phone.phoneNumberFormat() }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.address`]="{ item }">
                    {{ `${item?.user?.address1} ${item?.user?.address2}` }}
                </template>
                <template #[`item.isWinner`]="{ item }">
                    <v-row justify="center" align="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.isWinner" color="red lighten-1" hide-details dens class="mt-0" @change="updateCompletion({ _id: item._id, isWinner: item.isWinner })" />
                        </v-col>
                    </v-row>
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <!-- <v-btn bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn> -->
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                _course: this.$route.query._course,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            completions: [],
            completionsHeaders: [
                { text: "no", align: "center", width: 40, value: "no" },
                { text: "이름", align: "center", width: 150, value: "user.username" },
                { text: "연락처", align: "center", width: 100, value: "user.phone" },
                { text: "코스이름", align: "center", width: 100, value: "course.name" },
                { text: "응모번호", align: "center", width: 70, value: "entryNo" },
                { text: "주소", align: "center", width: 150, value: "address" },
                { text: "당첨여부", align: "center", width: 70, value: "isWinner" },
                { text: "응모일", align: "center", width: 100, value: "createdAt" },
                // { text: "Actions", width: 40, align: "center", value: "actions" },
            ],



            searchKeys: [
                { text: "전체", value: null },
                { text: "이름", value: "username" },
                { text: "연락처", value: "phone" },
                { text: "응모번호", value: "entryNo" },
            ],
            couresItems: [
                { text: "전체", value: null },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        async init() {
            let { courses } = await api.console.courses.gets({})
            courses.map(el => { this.couresItems.push({ text: el.name, value: el._id }) }) 
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, completions } = await api.console.completions.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.completions = completions;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async updateCompletion(completion) {
            try {
                await api.console.completions.put(completion);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
        // create() {
        //     this.$router.push(`/console/completions/create`);
        // },
        // edit(course) {
        //     this.$router.push(`/console/completions/${course._id}`);
        // },
        // async remove(board) {
        //     try {
        //         if (confirm("게시글을 삭제하시겠습니까?")) {
        //             await api.console.completions.delete(board);
        //             await this.search(false);

        //             alert("삭제되었습니다");
        //         }
        //     } catch (error) {
        //         console.error(error);
        //         alert(error.response ? error.response.data.message : error.message);
        //     }
        // },

        async excel() {
            try {
                var { completions } = await api.console.completions.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.filter,
                });

                var rows = completions.map((completion) => ({
                    이름: completion?.user?.username,
                    연락처: completion?.user?.phone?.phoneNumberFormat(),
                    응모번호: completion?.entryNo,
                    당첨여부: completion?.isWinner ? "당첨" : "미당첨",
                    주소: `${completion?.user?.address1} ${completion?.user?.address2}`,
                    응모일: this.$dayjs(completion?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                }));

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "응모번호 목록.xlsx");
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            }
        },
    },
};
</script>