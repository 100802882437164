<template>
    <v-dialog v-model="show" width="560">
        <v-card v-if="show">
            <v-card-title class="tit tit--xs font-weight-bold" primary-title>신규 카테고리 등록</v-card-title>
            <v-card-text class="pb-0">
                <category-select v-model="parent" :categories="categories" return-object :allowDepth="[1, 2]"></category-select>
                <v-text-field v-model="category.name" label="카테고리 이름" persistent-placeholder hide-details class="mt-4"></v-text-field>
                <v-text-field v-model="category.code" label="카테고리 코드" persistent-placeholder hide-details class="mt-4"></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close">취소</v-btn>
                <v-btn color="primary" text @click="save">등록</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/carbon/categories/category-select.vue";

export default {
    components: {
        CategorySelect
    },
    data(){
        return {
            show: false,

            categories: [],
            category: {},

            parent: null,
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            let { categories } = await api.console.carbon.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
            this.category = {
                name: null,
                code: null,
            };

            this.parent = null;
        },
        open(){
            this.show = true;
        },
        close(){
            this.show = false;
        },
        async save(){
            this.category.depth = parseInt(+this?.parent?.depth + 1 || 1);
            this.category._parents = this.parent ? [...this.parent._parents, this.parent._id] : [];
            let { category } = await api.console.carbon.categories.post(this.category);
            if(this.parent){
                this.parent._children.push(category._id);
                await api.console.carbon.categories.put(this.parent);
            }

            alert("저장되었습니다");

            this.$emit("save");
            this.close();
        }
    },
    watch: {
        show(){
            if(!this.show) this.init();
        }
    }
}
</script>