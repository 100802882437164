<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.params._course" class="headline text-start mt-8">코스 등록</div>
            <div v-else class="headline text-start mt-8">코스 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1 pb-0">기본정보</v-card-title>
                <v-card-text>
                    <v-row class="mt-3">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="course.name" label="코스 이름" persistent-placeholder hide-details></v-text-field>
                            <v-text-field v-model="course.applicationCriteria" class="mt-5" label="응모기준" persistent-placeholder hide-details></v-text-field>
                            <v-text-field v-model="course.distance" class="mt-5" label="완주 거리" persistent-placeholder hide-details></v-text-field>
                            <v-text-field v-model="course.calorie" class="mt-5" label="칼로리 감소" persistent-placeholder hide-details></v-text-field>
                            <v-text-field v-model="course.carbon" class="mt-5" label="탄소 감소" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-color-picker v-model="course.color" dot-size="25" swatches-max-height="200" mode="hexa" />
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="6" class="py-0">
                            <v-textarea v-model="course.info" label="코스 설명" persistent-placeholder hide-details/>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-textarea v-model="course.successInfo" label="완주 설명" persistent-placeholder hide-details/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">이미지</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="course?.image?.src" max-width="96" max-height="96" :src="course?.image?.src" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="course.image.name" label="지도 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*" ref="file" @change="(file) => upload(file, 'image')"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">지도 핀 이미지</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="course?.pinImage?.src" max-width="96" max-height="96" :src="course?.pinImage?.src" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="course.pinImage.name" label="지도 핀 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*" ref="file" @change="(file) => upload(file, 'pinImage')"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4" v-if="scope.includes('root')">
                <v-card-title class="subtitle-1 pb-0">지점정보</v-card-title>
                <v-card-text>
                    <v-btn color="primary" @click="course.waypoints.push({ name: null, latitude: null, longitude: null })">지점추가</v-btn>
                    <v-btn color="primary" @click="deleteWaypoint">지점삭제</v-btn>
                </v-card-text>
                <v-card-text>
                    <v-row v-for="waypoint, index in course.waypoints" :key="index" class="mt-3">
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.name" label="이름" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.latitude" label="위도" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0">
                            <v-text-field v-model="waypoint.longitude" label="경도" persistent-placeholder hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";

import { mdiTagPlus } from "@mdi/js";

export default {
    components: {
        VDatetimeField,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 코스 정보
            ///////////////////////////////////////////////
            course: {
                name: null,
                color: null,
                image: {
                    src: null,
                    name: null
                },
                pinImage: {
                    src: null,
                    name: null
                },
                applicationCriteria: null,
                waypoints: [
                    { name: null, latitude: null, longitude: null },
                ]
            },
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {

                if (this.$route.params._course) {
                    var { course } = await api.console.courses.get({ _id: this.$route.params._course });
                    this.course = course;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        validate() {
            try {
                this.course.applicationCriteria = Number(this.course.applicationCriteria)
                if (!this.course.name) throw new Error("코스 이름을 입력해주세요");
                if (!this.course.applicationCriteria) throw new Error("코스 응모기준을 입력해주세요");
                console.log(typeof this.course.applicationCriteria)
                if (typeof this.course.applicationCriteria !== "number") throw new Error("응모 기준은 숫자만 입력 가능합니다");
                if (!this.course.image.src || !this.course.image.name ) throw new Error("이미지를 선택해주세요");

                for (let waypoint of this.course.waypoints) {
                    if (!waypoint.name) throw new Error("지점 이름을 입력해주세요");
                    if (!waypoint.latitude) throw new Error("위도를 입력해주세요");
                    if (!waypoint.longitude) throw new Error("경도를 입력해주세요");
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            try {
                if (this.validate()) {
                    let { course } = this.course._id ? await api.console.courses.put(this.course) : await api.console.courses.post(this.course)
    
                    alert(this.course._id ? "수정되었습니다" : "저장되었습니다");
    
                    this.$router.go(-1);
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        async deleteWaypoint() {
            let waypoint = this.course.waypoints.pop()
            console.log(waypoint)
            if (waypoint._id) {
                await api.console.waypoints.delete({ _id: waypoint._id })
            }
        },
        async upload(file, res) {
            let { src, name } = await api.resource.post(file, `/res/course/${res}`);
            this.$set(this.course, `${res}`, { src, name })
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        }
    },
};
</script>
