<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                <span v-if="carbon._id">탄소중립 상세</span>
                <span v-else>탄소중립 등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="pb-2">
                <span class="tit tit--xs"><b>카테고리</b></span>
                <v-spacer/>
            </v-card-title>
            <v-card-text>
                <v-layout align-end :class="{ 'mt-4': 0 ? true : false }">
                    <category-select v-model="carbon._category" :categories="categories" :allowDepth="[1]"/>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-row class="mt-4">
            <v-spacer />
            <v-col cols="12" md="5" class="pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        노출여부 (미노출/노출)
                        <v-spacer />
                        <v-switch v-model="carbon.onDisplay"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="pb-2">
                <span class="tit tit--xs"><b>기본 정보</b></span>
                <v-spacer/>
            </v-card-title>
            <v-card-text>  
                <v-row>
                    <v-col>
                        <v-text-field v-model="carbon.subject" label="제목" persistent-placeholder hide-details="" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="carbon.point" label="마일리지" persistent-placeholder hide-details="" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <!-- <v-text-field v-model="carbon.cycle" label="주기" persistent-placeholder hide-details="" /> -->
                        <v-select v-model="carbon.cycle1" :items="cycle1Items" label="주기1" persistent-placeholder  />
                    </v-col>
                    <v-col>
                        <!-- <v-text-field v-model="carbon.cycle" label="주기" persistent-placeholder hide-details="" /> -->
                        <v-select v-model="carbon.cycle2" :items="cycle2Items" label="주기2" persistent-placeholder  />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field v-model="carbon.gases" label="온실가스 감축량" persistent-placeholder hide-details="" />
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mx-1 mt-4">
            <v-card-title class="pb-2">
                <span class="tit tit--xs"><b>내용</b></span>
                <v-spacer/>
            </v-card-title>
            <v-card-text>
                <naver-smarteditor v-model="carbon.content" />
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/carbon/categories/category-select.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
export default {
    components: {
        CategorySelect,
        NaverSmarteditor
    },
    data(){
        return {
            ready: false,

            carbon: {
                _id: this.$route.params._carbon,
                _category: null,

                subject: null,
                content: null,
                point: 0,
                gases: null,
                cycle1: null,
                cycle2: null,
                cycle3: null,

                onDisplay: true,
            },

            categories: [],
            cycle1Items: [
                { text: "1", value: 1 },
                { text: "2", value: 2 },
                { text: "3", value: 3 },
                { text: "4", value: 4 },
                { text: "5", value: 5 },
                { text: "6", value: 6 },
                { text: "7", value: 7 },
                { text: "8", value: 8 },
                { text: "9", value: 9 },
                { text: "10", value: 10 },
            ],
            cycle2Items: [
                { text: "일", value: "day" },
                { text: "주", value: "week" },
                { text: "달", value: "month" },
                { text: "년", value: "year" },
            ]
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){

            // 카테고리 조회
            let { categories } = await api.console.carbon.categories.gets({ params: { depth: 1 }});
            this.categories = categories;

            if(this.carbon._id){
                let { carbon } = await api.console.carbon.carbons.get(this.carbon);
                this.carbon = carbon;
            }

            this.ready = true;
        },
        vaildate() {
            try {
                if (!this.carbon._category) throw new Error("카테고리를 선택해주세요");
                if (!this.carbon.subject) throw new Error("제목을 입력해주세요");
                if (!this.carbon.point || this.carbon.point <= 0) throw new Error("마일리지를 입력해주세요")
                if (!this.carbon.gases) throw new Error("온실가스 감축량을 입력해주세요");
                if (!this.carbon.cycle1) throw new Error("주기1를 선택해주세요");
                if (!this.carbon.cycle2) throw new Error("주기2를 선택해주세요");
                if (!this.carbon.content) throw new Error("내용을 입력해주세요");
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save(){
            if (this.vaildate()) {
                this.carbon._id ? await api.console.carbon.carbons.put({ ...this.carbon }) : await api.console.carbon.carbons.post({ ...this.carbon});
                alert("저장되었습니다");
                this.$router.go(-1);
            }
        },
        async upload(file, res) {
            let { src, name } = await api.resource.post(file, `/res/carbons/${res}`);
            console.log({ src, name })
            this.$set(this.carbon, `${res}`, { src, name })
        },
    }
}
</script>