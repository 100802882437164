<template>
    <v-expand-transition>
        <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
            <v-card-title class="tit tit--xs font-weight-bold">검색필터</v-card-title>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="6">
                        <v-date-field v-model="query.createdAt[0]" label="시작일자" type="date" persistent-placeholder hide-details clearable @input="setQuery"/>
                    </v-col>
                    <v-col cols="6">
                        <v-date-field v-model="query.createdAt[1]" label="종료일자" type="date" persistent-placeholder hide-details clearable @input="setQuery"/>
                    </v-col>
                    <v-col cols="4">
                        <!-- <v-select v-model="filter.status" :items="statusItems" item-text="text" item-value="value" label="상태" persistent-placeholder hide-details @input="filter.searchValue = null;"/> -->
                        <v-select v-model="query.status" :items="statusItems" item-text="text" item-value="value" label="상태" persistent-placeholder hide-details @input="setQuery"/>
                    </v-col>
                    <v-col cols="4">
                        <v-select v-model="query.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" persistent-placeholder hide-details />
                    </v-col>
                    <v-col cols="4">
                        <v-text-field v-model="query.searchValue" label="검색어" persistent-placeholder hide-details clearable :disabled="!query.searchKey" @keydown.enter="setQuery" />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded text @click="reset">
                    <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                </v-btn>
                <v-btn color="primary" rounded text @click="setQuery">
                    <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-expand-transition>
</template>

<script>
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
const initQuery = (query = {}) => ({
    createdAt : query?.createdAt || [],
    searchKey : query?.searchKey || null,
    searchValue : query?.searchValue || null,
    status : query?.status || null,
    page: query?.page || 1
})
export default {
    components: {
        VDateField
    },
    props: {
        showSearch : { type: Boolean, default: false },
    },
    data() {
        return {
            query: initQuery(),
            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "제목", value: "carbon.subject" },
                { text: "회원 이름", value: "user.username" },
                { text: "회원 연락처", value: "user.phone" },
            ],

            statusItems: [
                { text: ":: 전체 ::", value: null },
                { text: "검수중", value: "검수중" },
                { text: "지급거부", value: "지급거부" },
                { text: "마일리지 지급", value: "마일리지 지급" },
            ]
        }
    },
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = initQuery(this.value);
        },
        setQuery() {
            const { ...query } = this.query || {};
            query.page = 1;
            this.$router.push({ query });
        },
        reset() {
            this.query = initQuery();
            this.setQuery();
        },
    }
}
</script>

<style>

</style>