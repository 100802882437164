<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="tit tit--lg">
                <span v-if="order._id">주문상세</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card>
            <v-card-title>
                상품 정보
            </v-card-title>
            <v-card-text>
                <v-data-table :items="[order.product]" item-key="_id" :headers="productHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
                    <template #[`item.thumb`]="{ item }">
                        <v-img :src="item.thumb.src" max-width="96" height="96" contain class="ma-auto"/>
                    </template>
                    <template #[`item.price`]="{ item }">
                        {{ item.price.format() }} 원
                    </template>
                </v-data-table>
                <v-row class="mt-4">
                    <v-spacer/>
                    <v-btn color="red" @click="refund" :disabled="order.isRefund">마일리지 환불</v-btn>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="mt-4">
            <v-card-title>
                주문정보
            </v-card-title>
            <v-card-text>
                <v-row >
                    <v-col>
                        <v-text-field v-model="order.orderNo" label="주문번호" readonly persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="$dayjs(order.createdAt).format('YYYY-MM-DD HH:mm:ss')" label="주문일" readonly persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col>
                        <v-text-field v-model="order.user.username" label="회원 이름" readonly persistent-placeholder hide-details></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field :value="order.user.phone.phoneNumberFormat()" label="회원 연락처" readonly persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row >
                    <v-col>
                        <v-select v-model="order.status" :items="statusItems" label="상태" persistent-placeholder hide-details />
                    </v-col>
                    <v-col></v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    data(){
        return {
            ready: false,

            order: {
                _id: this.$route.params._order,
            },
            productHeaders: [
                { text: "이미지", value: "thumb", align: "center", width: 100  },
                { text: "상품이름", value: "name", align: "center", width: 100  },
                { text: "제품가격", value: "price", align: "center", width: 100  },
            ],
            statusItems: [
                { text: "지급완료", value: "지급완료" },
                { text: "지급거부", value: "지급거부" },
                { text: "신청완료", value: "신청완료" },
            ]
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){

            if(this.order._id){
                let { order } = await api.console.shop.orders.get(this.order);
                this.order = order;
            }

            this.ready = true;
        },
        vaildate() {
            try {
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save(){
            if (this.vaildate()) {
                this.order._id ? await api.console.shop.orders.put({ ...this.order }) : await api.console.shop.orders.post({ ...this.order});
                alert("저장되었습니다");
                this.$router.go(-1);
            }
        },
        async refund() {
            if (confirm("마일리지를 환불하시겠습니까?")) {
                let { order } = await api.console.shop.orders.refund(this.order);
                console.log(order)
                this.order = order;
                console.log(this.order)
                alert("마일리지가 환불되었습니다.")
            }
        }
    }
}
</script>