var render = function render(){
  var _vm$product, _vm$product$thumb, _vm$product2, _vm$product2$thumb;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.product._id ? _c('span', [_vm._v("상품상세")]) : _c('span', [_vm._v("상품등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('span', {
    staticClass: "tit tit--xs"
  }, [_c('b', [_vm._v("카테고리")])]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-layout', {
    class: {
      'mt-4': 0 ? true : false
    },
    attrs: {
      "align-end": ""
    }
  }, [_c('category-select', {
    attrs: {
      "categories": _vm.categories,
      "allowDepth": [1]
    },
    model: {
      value: _vm.product._category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_category", $$v);
      },
      expression: "product._category"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_vm._v(" 노출여부 (미노출/노출) "), _c('v-spacer'), _c('v-switch', {
    model: {
      value: _vm.product.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.product, "onDisplay", $$v);
      },
      expression: "product.onDisplay"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    staticClass: "pr-md-1",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-card', {
    staticClass: "mx-1"
  }, [_c('v-card-title', {
    staticClass: "tit tit--xs font-weight-bold"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-layout', {
    attrs: {
      "no-gutters": "",
      "align-center": ""
    }
  }, [(_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$thumb = _vm$product.thumb) !== null && _vm$product$thumb !== void 0 && _vm$product$thumb.src ? _c('v-img', {
    attrs: {
      "src": (_vm$product2 = _vm.product) === null || _vm$product2 === void 0 ? void 0 : (_vm$product2$thumb = _vm$product2.thumb) === null || _vm$product2$thumb === void 0 ? void 0 : _vm$product2$thumb.src,
      "max-width": "96",
      "height": "auto",
      "contain": ""
    }
  }) : _c('v-sheet', {
    attrs: {
      "width": "96",
      "height": "96",
      "color": "d-flex justify-center align-center grey lighten-2"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1), _c('v-file-input', {
    on: {
      "change": function (file) {
        return _vm.upload(file, 'thumb');
      }
    },
    model: {
      value: _vm.product.thumb.name,
      callback: function ($$v) {
        _vm.$set(_vm.product.thumb, "name", $$v);
      },
      expression: "product.thumb.name"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_c('span', {
    staticClass: "tit tit--xs"
  }, [_c('b', [_vm._v("기본정보")])])]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "상품이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "판매가",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  })], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }