var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1200px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "headline text-start mt-8"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.banner._id ? "배너 상세보기" : "배너 등록"))])]), _c('v-spacer')], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("슬라이드")]), _c('v-card-text', [_c('draggable', {
    model: {
      value: _vm.banner.slides,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "slides", $$v);
      },
      expression: "banner.slides"
    }
  }, [_c('transition-group', _vm._l(_vm.banner.slides, function (slide, index) {
    return _c('v-list-item', {
      key: `${index}`
    }, [_c('v-list-item-action', [_c('v-preview-input', {
      attrs: {
        "min-width": "96",
        "min-height": "96",
        "max-width": "96",
        "max-height": "96",
        "contain": ""
      },
      model: {
        value: slide.image,
        callback: function ($$v) {
          _vm.$set(slide, "image", $$v);
        },
        expression: "slide.image"
      }
    })], 1), _c('v-list-item-content', [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "sm": "8"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "URL",
        "placeholder": "ex)https://naver.com",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: slide.url,
        callback: function ($$v) {
          _vm.$set(slide, "url", $$v);
        },
        expression: "slide.url"
      }
    })], 1)], 1)], 1), _c('v-spacer'), _c('v-list-item-action', [_c('v-icon', {
      on: {
        "click": function ($event) {
          return _vm.banner.slides.splice(index, 1);
        }
      }
    }, [_vm._v("mdi-delete")])], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-3",
    attrs: {
      "outlined": "",
      "color": "blue",
      "width": "100%"
    },
    on: {
      "click": _vm.addImage
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiCloudUpload))]), _c('span', [_vm._v("배너 이미지 추가")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "pa-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.name,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "name", $$v);
      },
      expression: "banner.name"
    }
  })], 1), _c('v-card', {
    staticClass: "pa-3 mt-3"
  }, [_c('v-text-field', {
    attrs: {
      "label": "코드",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.banner.code,
      callback: function ($$v) {
        _vm.$set(_vm.banner, "code", $$v);
      },
      expression: "banner.code"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1), _c('daum-postcode', {
    ref: "DaumPostcode",
    on: {
      "input": function (_ref) {
        var postcode = _ref.postcode,
          address = _ref.address;
        _vm.banner.postcode = postcode;
        _vm.banner.address1 = address;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }