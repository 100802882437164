var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1440",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-3 mt-md-8 px-1",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.carbon._id ? _c('span', [_vm._v("탄소중립 상세")]) : _c('span', [_vm._v("탄소중립 등록")])]), _c('v-spacer')], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('span', {
    staticClass: "tit tit--xs"
  }, [_c('b', [_vm._v("카테고리")])]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-layout', {
    class: {
      'mt-4': 0 ? true : false
    },
    attrs: {
      "align-end": ""
    }
  }, [_c('category-select', {
    attrs: {
      "categories": _vm.categories,
      "allowDepth": [1]
    },
    model: {
      value: _vm.carbon._category,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "_category", $$v);
      },
      expression: "carbon._category"
    }
  })], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "pl-md-1",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-card', {
    staticClass: "mx-1 mt-3 mt-md-0"
  }, [_c('v-card-title', [_vm._v(" 노출여부 (미노출/노출) "), _c('v-spacer'), _c('v-switch', {
    model: {
      value: _vm.carbon.onDisplay,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "onDisplay", $$v);
      },
      expression: "carbon.onDisplay"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('span', {
    staticClass: "tit tit--xs"
  }, [_c('b', [_vm._v("기본 정보")])]), _c('v-spacer')], 1), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "제목",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.carbon.subject,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "subject", $$v);
      },
      expression: "carbon.subject"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "마일리지",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.carbon.point,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "point", $$v);
      },
      expression: "carbon.point"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.cycle1Items,
      "label": "주기1",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.carbon.cycle1,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "cycle1", $$v);
      },
      expression: "carbon.cycle1"
    }
  })], 1), _c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.cycle2Items,
      "label": "주기2",
      "persistent-placeholder": ""
    },
    model: {
      value: _vm.carbon.cycle2,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "cycle2", $$v);
      },
      expression: "carbon.cycle2"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "온실가스 감축량",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.carbon.gases,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "gases", $$v);
      },
      expression: "carbon.gases"
    }
  })], 1), _c('v-col')], 1)], 1)], 1), _c('v-card', {
    staticClass: "mx-1 mt-4"
  }, [_c('v-card-title', {
    staticClass: "pb-2"
  }, [_c('span', {
    staticClass: "tit tit--xs"
  }, [_c('b', [_vm._v("내용")])]), _c('v-spacer')], 1), _c('v-card-text', [_c('naver-smarteditor', {
    model: {
      value: _vm.carbon.content,
      callback: function ($$v) {
        _vm.$set(_vm.carbon, "content", $$v);
      },
      expression: "carbon.content"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "my-6"
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1), _c('v-spacer')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }