<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">실천참여 내역</v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>

        <carbon-participants-search v-bind="{ showSearch, value: params }" @search="init(true)"/>

        <v-row align="center">
            <v-col cols="auto" class="pt-2 pb-0">
                <v-card outlined v-bind="{ loading }">
                    <v-btn color="white" class="caption" v-bind="{ loading }">
                        <v-row>
                            <v-col>
                                <v-layout class="my-2" align-baseline>
                                    <span>검색 결과: </span>
                                    <b>{{ (summary?.totalCount || 0)?.format() }}</b>건
                                </v-layout>
                            </v-col>
                            <v-col>
                                <v-layout class="my-2" align-baseline>
                                    <span>마일리지 지급 금액: </span>
                                    <b>{{ (totalPoint_completed?.totalPoint || 0)?.format() }}</b>원
                                </v-layout>
                            </v-col>
                            <v-col>
                                <v-layout class="my-2" align-baseline>
                                    <span>검수중 금액: </span>
                                    <b>{{ (totalPoint_pending?.totalPoint || 0)?.format() }}</b>원
                                </v-layout>
                            </v-col>
                            <v-col>
                                <v-layout class="my-2" align-baseline>
                                    <span>지급거부 금액: </span>
                                    <b>{{ (totalPoint_rejected?.totalPoint || 0)?.format() }}</b>원
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col cols="auto" class="py-0">
                <v-card outlined v-bind="{ loading }">
                    <v-btn color="white" class="caption" v-bind="{ loading }">
                        <v-row>
                            <v-col>
                                <v-layout class="mx-2" align-baseline>
                                    <span>지급 기능: </span>
                                    <v-btn small outlined color="primary" class="mx-2" v-bind="{ loading }" @click="giveAll">마일리지 지급</v-btn>
                                    <v-btn small outlined color="primary" class="mr-2" v-bind="{ loading }" @click="rejectAll">지급 거부</v-btn>
                                </v-layout>
                            </v-col>
                        </v-row>
                    </v-btn>
                </v-card>
            </v-col>
            <v-spacer/>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2 ml-1" v-bind="{ loading }" @click="excel"> <v-icon>mdi-file-excel</v-icon> 엑셀다운로드 </v-btn>
                </v-col>
        </v-row>

        <v-data-table v-model="selected" :items="participants" item-key="_id" :headers="participantsHeaders" hide-default-footer disable-sort disable-pagination show-select class="mt-2 mx-1 elevation-1">
            <template #[`item.no`]="{ item }">
                {{ (page - 1) * limit + (+participants.indexOf(item) + 1) }}
            </template>
            <template #[`item.user`]="{ item }">
                <div>{{ item.user.username }}</div>
                <div>{{ item.user.phone.phoneNumberFormat() }}</div>
            </template>
            <template #[`item.carbon.point`]="{ item }">
                {{ item.carbon.point.format() }}원
            </template>
            <template #[`item.user.point`]="{ item }">
                {{ item.user.point.format() }}원
            </template>
            <template #[`item.images`]="{ item }">
                <v-list-item>
                    <v-list-item-avatar v-for="(image, index) in item.images" :key="index">
                        <image-popup v-bind="{ src: image.src, size: '30', rounded: true }"></image-popup>
                    </v-list-item-avatar>
                </v-list-item>
            </template>
            <template #[`item.gases`]="{ item }">
                {{ item.gases }}
            </template>
            <v-img slot="item.thumb" slot-scope="{item}" :src="item.thumb.src" max-width="96" height="96" contain class="ma-auto"/>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <v-icon class="mx-1" @click="remove(item)">mdi-delete</v-icon>
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <!-- <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn> -->
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import XLSX from "xlsx";
import dayjs from "@/plugins/vue-dayjs";
import { attrs_input } from "@/assets/variables";
import { mdiMicrosoftExcel } from "@mdi/js";
import CarbonParticipantsSearch from "@/components/console/carbon/participants/carbon-participants-search.vue";
import ImagePopup from "@/components/console/carbon/participants/image-popup.vue";

export default {
    components: {
        CarbonParticipantsSearch,
        ImagePopup
    },
    data(){
        return {
            attrs_input,
            mdiMicrosoftExcel,
            loading: false,
            shows: [],

            showSearch: true,

            categories: [],
            selected: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 20,

            selected: [],
            summary: { totalCount: 0 },
            totalPoint: [],
            participants: [],
            participantsHeaders: [
                { text: "no", value: "no", align: "center", width: 50  },
                { text: "탄소중립 제목", value: "carbon.subject", align: "center", width: 150 },
                { text: "회원정보", value: "user", align: "center", width: 150 },
                { text: "포인트", value: "carbon.point", align: "center", width: 100 },
                { text: "회원 포인트 총액", value: "user.point", align: "center", width: 100 },
                { text: "이미지", value: "images", align: "center", width: 100 },
                { text: "상태", value: "status", align: "center", width: 150  },
                { text: "등록일자", value: "createdAt", align: "center", width: 180 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],
        }
    },
    computed: {
        totalPoint_completed() {
            return this.totalPoint.filter(({ _id }) => _id === "마일리지 지급")[0];
        },
        totalPoint_pending() {
            return this.totalPoint.filter(({ _id }) => _id === "검수중")[0];
        },
        totalPoint_rejected() {
            return this.totalPoint.filter(({ _id }) => _id === "지급거부")[0];
        },
        params() {
            const { ...query } = this.$route.query;
            return query;
        },
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(routable=false){
            this.search(routable);
            this.getTotalPoint();
        },
        async search(routable = false){
            this.loading = false;
            if(this.loading) return;
            else this.loading = true;
            
            try {
                if(routable){
                    this.$router.push({
                        query: { ...this.params, page: this.page }
                    });
                }

                let { summary, participants } = await api.console.carbon.participants.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.params
                });
    
                this.participants = participants;
                this.summary = { ...summary};
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        async getTotalPoint() {
            try {
                let { totalPoint } = await api.console.carbon.participants.getsTotalPoint({
                    params: this.params
                });

                this.totalPoint = totalPoint;
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(product){
            if(confirm("실천참여 내역을 삭제하시면 복구할 수 없습니다\n삭제하시겠습니까?")){
                await api.console.carbon.participants.delete(product);
                alert("삭제되었습니다");
                this.init(false);
            }
        },
        async giveAll(){
            if(this.loading) return;
            else this.loading = true;

            try {
                if(!this.selected.length) throw new Error("대상을 선택해주세요.");
                if(confirm(`총 ${this.selected.length}개의 마일리지를 지급하시겠습니까?`)) {
                    for(let participant of this.selected) {
                        await api.console.carbon.participants.give(participant);
                    }
                    alert("마일리지가 지급되었습니다.");
                    this.selected = [];
                }
                this.loading = false;
                this.init(true);
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },

        async rejectAll(){
            if(this.loading) return;
            else this.loading = true;

            try {
                if(!this.selected.length) throw new Error("대상을 선택해주세요.");
                if(confirm(`총 ${this.selected.length}개의 마일리지 지급을 거부하시겠습니까?`)) {
                    for(let participant of this.selected) {
                        await api.console.carbon.participants.put({...participant, status: "지급거부"});
                    }
                    alert("지급 거부 처리되었습니다.");
                    this.selected = [];
                }
                this.loading = false;
                this.init(true);
            } catch(error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            } finally {
                this.loading = false;
            }
        },
        edit(product){
            this.$router.push(`/console/carbon/participants/${product._id}`);
        },
        async excel() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { participants } = await api.console.carbon.participants.gets({
                    headers: {
                        limit: 0,
                    },
                    params: this.params,
                });

                let rows = participants.map((participant) => ({
                    등록일자: participant?.createdAt?.toDateTime?.(),
                    탄소중립제목: participant?.carbon?.subject,
                    포인트: `${participant?.carbon?.point}원`,
                    성함: participant?.user?.username,
                    연락처: participant?.user?.phone,
                    상태: participant?.status,
                    지급여부: participant?.isGiven ? "지급" : "미지급"
                }));

                let workbook = new XLSX.utils.book_new();
                let worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, `실천참여내역_${dayjs().format(`YYYYMMDD_HHmmss`)}.xlsx`);
                this.loading = false;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.email : error.email);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        "$route.query"() {
            this.page = +this.$route.query.page || 1;
            this.init();
        }
    }
}
</script>