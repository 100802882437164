<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">

        <v-row class="mt-3 mt-md-8 px-1" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                <span v-if="product._id">상품상세</span>
                <span v-else>상품등록</span>
            </v-col>
            <v-spacer/>
        </v-row>

        <v-card class="mx-1 mt-4">
            <v-card-title class="pb-2">
                <span class="tit tit--xs"><b>카테고리</b></span>
                <v-spacer/>
            </v-card-title>
            <v-card-text>
                <v-layout align-end :class="{ 'mt-4': 0 ? true : false }">
                    <category-select v-model="product._category" :categories="categories" :allowDepth="[1]"/>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-row class="mt-4">
            <v-spacer />
            <v-col cols="12" md="5" class="pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        노출여부 (미노출/노출)
                        <v-spacer />
                        <v-switch v-model="product.onDisplay"/>
                    </v-card-title>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-4">
            <v-col cols="12" md="7" class="pr-md-1">
                <v-card class="mx-1">
                    <v-card-title class="tit tit--xs font-weight-bold">썸네일</v-card-title>
                    <v-card-text>
                        <v-layout no-gutters align-center>
                            <v-img v-if="product?.thumb?.src" :src="product?.thumb?.src" max-width="96" height="auto" contain></v-img>
                            <v-sheet v-else width="96" height="96" color="d-flex justify-center align-center grey lighten-2"><v-icon>mdi-image</v-icon></v-sheet>
                            <v-file-input v-model="product.thumb.name" @change="(file) => upload(file, 'thumb')"/>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="5" class="pl-md-1">
                <v-card class="mx-1 mt-3 mt-md-0">
                    <v-card-title>
                        <span class="tit tit--xs"><b>기본정보</b></span>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field v-model="product.name" label="상품이름" persistent-placeholder hide-details="" />
                        <v-text-field v-model="product.price" label="판매가" persistent-placeholder hide-details="" class="mt-4" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="my-6">
            <v-spacer/>
            <v-col cols="auto" class="py-0">
                <v-btn color="primary" @click="save">저장</v-btn>
            </v-col>
            <v-spacer/>
        </v-row>
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/shop/categories/category-select.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";
import { mdiFruitGrapesOutline } from "@mdi/js";
export default {
    components: {
        CategorySelect,
        NaverSmarteditor
    },
    data(){
        return {
            ready: false,

            product: {
                _id: this.$route.params._product,
                _category: null,

                name: null,
                price: 0,
                onDisplay: true,

                thumb: {
                    name: null,
                    src: null
                },
            },

            categories: [],
        }
    },
    async mounted(){
        await this.init();
    },
    methods: {
        async init(){

            // 카테고리 조회
            let { categories } = await api.console.shop.categories.gets({ params: { depth: 1 }});
            this.categories = categories;

            if(this.product._id){
                let { product } = await api.console.shop.products.get(this.product);
                this.product = product;
            }

            this.ready = true;
        },
        vaildate() {
            try {
                if (!this.product._category) throw new Error("카테고리를 선택해주세요");
                if (!this.product.thumb.src || !this.product.thumb.name) throw new Error("썸네일을 선택해주세요");
                if (!this.product.name) throw new Error("상품 이름을 입력해주세요");
                if (!this.product.price || this.product.price <= 0) throw new Error("판매가를 입력해주세요")
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save(){
            if (this.vaildate()) {
                this.product._id ? await api.console.shop.products.put({ ...this.product }) : await api.console.shop.products.post({ ...this.product});
                alert("저장되었습니다");
                this.$router.go(-1);
            }
        },
        async upload(file, res) {
            let { src, name } = await api.resource.post(file, `/res/products/${res}`);
            console.log({ src, name })
            this.$set(this.product, `${res}`, { src, name })
        },
    }
}
</script>