<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
            <v-row class="mt-3 mt-md-8 px-1" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">
                    <span v-if="participant._id">사용신청 상세</span>
                </v-col>
                <v-spacer />
            </v-row>

            <v-row>
                <v-col>
                    <v-card class="mt-4">
                        <v-card-title> 기본정보 </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    <v-text-field v-model="participant.user.username" label="회원 이름" readonly persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-text-field :value="participant.user.phone.phoneNumberFormat()" label="회원 연락처" readonly persistent-placeholder hide-details></v-text-field>
                                </v-col>
                                <v-col cols="3">
                                    <v-select v-model="participant.status" :items="statusItems" label="상태" persistent-placeholder hide-details />
                                </v-col>
                                <v-col cols="3">
                                    <span v-if="!participant.user.point">포인트가 존재하지 않습니다.</span>
                                    <v-currency-field v-else v-model="participant.user.point" label="총 마일리지 금액" readonly suffix="원"/>
                                </v-col>
                                <v-col cols="12">
                                    <v-card outlined>
                                        <v-card-title class="subtitle-2 font-weight-bold grey--text text--darken-2">
                                            <v-row no-gutters align="center">
                                                <v-col class="text-truncate pr-3">신청 이미지</v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider />
                                        <v-card-text>
                                            <v-card v-for="(image, index) in participant.images" :key="index" flat>
                                                <v-hover v-slot="{ hover }">
                                                    <v-img :src="image.src" :aspect-ratio="1 / 1" contain max-height="300px">
                                                        <v-fade-transition>
                                                            <v-overlay v-show="!image.src" light color="transparent" absolute>
                                                                <v-icon color="grey">mdi-image</v-icon>
                                                            </v-overlay>
                                                        </v-fade-transition>
                                                        <v-fade-transition v-if="image.src">
                                                            <v-overlay absolute v-show="hover">
                                                                <v-dialog v-model="shows[index]" max-width="85vh" width="85%" :disabled="!image.src" content-class="black">
                                                                    <template #activator="{ attrs, on }">
                                                                        <v-btn icon v-bind="attrs" v-on="on" class="mr-2">
                                                                            <v-icon>mdi-eye</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <v-img v-if="image.src" :src="image.src" max-height="85vh" contain />
                                                                </v-dialog>
                                                            </v-overlay>
                                                        </v-fade-transition>
                                                    </v-img>
                                                </v-hover>
                                            </v-card>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="mt-4">
                        <v-card-title> 관리자 메모 </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-textarea v-model="participant.memo" outlined dense />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-4">
                        <v-card-title> 탄소중립 실천 정보 </v-card-title>
                        <v-card-text>
                            <v-data-table :items="[participant.carbon]" item-key="_id" :headers="productHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
                                <template #[`item.point`]="{ item }"> {{ item.point.format() }} 원 </template>
                                <template #[`item.gases`]="{ item }"> {{ item.gases }}g </template>
                            </v-data-table>
                            <v-row class="mt-4">
                                <v-spacer />
                                <v-btn color="primary" @click="give" :disabled="this.participant.isGiven">마일리지 지급</v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="my-6">
                <v-spacer />
                <v-col cols="auto" class="py-0">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
                <v-spacer />
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";

export default {
    data() {
        return {
            ready: false,
            shows: [],

            participant: {
                _id: this.$route.params._participant,
            },
            productHeaders: [
                { text: "제목", value: "subject", align: "center", width: 100 },
                { text: "포인트", value: "point", align: "center", width: 100 },
                { text: "온실가스 감축량", value: "gases", align: "center", width: 100 },
                { text: "주기", value: "cycle", align: "center", width: 100 },
            ],
            imageHeaders: [{ text: "이미지", value: "image", align: "center", width: 100 }],
            statusItems: [
                { text: "검수중", value: "검수중" },
                { text: "지급거부", value: "지급거부" },
                { text: "마일리지 지급", value: "마일리지 지급" },
            ],
        };
    },
    async mounted() {
        await this.init();
    },
    methods: {
        async init() {
            if (this.participant._id) {
                let { participant } = await api.console.carbon.participants.get(this.participant);
                this.participant = participant;
            }

            this.ready = true;
        },
        vaildate() {
            try {
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if (this.vaildate()) {
                this.participant._id ? await api.console.carbon.participants.put({ ...this.participant }) : await api.console.carbon.participants.post({ ...this.participant });
                alert("저장되었습니다");
                this.$router.go(-1);
            }
        },
        async give() {
            if (confirm("마일리지를 지급하시겠습니까?")) {
                let { participant } = await api.console.carbon.participants.give(this.participant);
                this.participant = participant;
                alert("마일리지가 지급되었습니다.");
                this.init();
            }
        },
        isRefund() {
            return this.order.refundedPointAmount === this.order.totalAmount;
        },
    },
};
</script>