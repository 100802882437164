<template>
    <v-menu ref="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
        <template #activator="{ on }">
            <v-text-field v-model="date" :label="label" :placeholder="placeholder" :dense="dense" :hide-details="hideDetails" append-icon="event" readonly v-on="on" persistent-placeholder :clearable="clearable"/>
        </template>
        <v-date-picker v-model="date" :type="type" no-title scrollable @input="$refs.menu.save(date)"></v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: { type: String, default: undefined },

        type: { type: String, default: 'date' },
        label: { type: String, default: undefined },
        placeholder: { type: String, default: undefined },
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        clearable: { type: Boolean, default: false }
    },
    data(){
        return {
            date: this.$props.value
        };
    },
    watch: {
        date(){
            this.$emit('input', this.date);
        },
        value(){
            this.date = this.value;
        }
    }
}
</script>
