var render = function render(){
  var _vm$course, _vm$course$image, _vm$course2, _vm$course2$image, _vm$course3, _vm$course3$pinImage, _vm$course4, _vm$course4$pinImage;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-board-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.params._course ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("코스 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("코스 상세")]), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "코스 이름",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.name,
      callback: function ($$v) {
        _vm.$set(_vm.course, "name", $$v);
      },
      expression: "course.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "응모기준",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.applicationCriteria,
      callback: function ($$v) {
        _vm.$set(_vm.course, "applicationCriteria", $$v);
      },
      expression: "course.applicationCriteria"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "완주 거리",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.distance,
      callback: function ($$v) {
        _vm.$set(_vm.course, "distance", $$v);
      },
      expression: "course.distance"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "칼로리 감소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.calorie,
      callback: function ($$v) {
        _vm.$set(_vm.course, "calorie", $$v);
      },
      expression: "course.calorie"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "탄소 감소",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.carbon,
      callback: function ($$v) {
        _vm.$set(_vm.course, "carbon", $$v);
      },
      expression: "course.carbon"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-color-picker', {
    attrs: {
      "dot-size": "25",
      "swatches-max-height": "200",
      "mode": "hexa"
    },
    model: {
      value: _vm.course.color,
      callback: function ($$v) {
        _vm.$set(_vm.course, "color", $$v);
      },
      expression: "course.color"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "코스 설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.info,
      callback: function ($$v) {
        _vm.$set(_vm.course, "info", $$v);
      },
      expression: "course.info"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "label": "완주 설명",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.course.successInfo,
      callback: function ($$v) {
        _vm.$set(_vm.course, "successInfo", $$v);
      },
      expression: "course.successInfo"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("이미지")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [(_vm$course = _vm.course) !== null && _vm$course !== void 0 && (_vm$course$image = _vm$course.image) !== null && _vm$course$image !== void 0 && _vm$course$image.src ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": (_vm$course2 = _vm.course) === null || _vm$course2 === void 0 ? void 0 : (_vm$course2$image = _vm$course2.image) === null || _vm$course2$image === void 0 ? void 0 : _vm$course2$image.src,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    ref: "file",
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "지도 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    on: {
      "change": function (file) {
        return _vm.upload(file, 'image');
      }
    },
    model: {
      value: _vm.course.image.name,
      callback: function ($$v) {
        _vm.$set(_vm.course.image, "name", $$v);
      },
      expression: "course.image.name"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("지도 핀 이미지")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [(_vm$course3 = _vm.course) !== null && _vm$course3 !== void 0 && (_vm$course3$pinImage = _vm$course3.pinImage) !== null && _vm$course3$pinImage !== void 0 && _vm$course3$pinImage.src ? _c('v-img', {
    attrs: {
      "max-width": "96",
      "max-height": "96",
      "src": (_vm$course4 = _vm.course) === null || _vm$course4 === void 0 ? void 0 : (_vm$course4$pinImage = _vm$course4.pinImage) === null || _vm$course4$pinImage === void 0 ? void 0 : _vm$course4$pinImage.src,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    ref: "file",
    staticClass: "mt-2 mx-3",
    attrs: {
      "label": "지도 핀 이미지",
      "persistent-placeholder": "",
      "show-size": "",
      "accept": "image/*"
    },
    on: {
      "change": function (file) {
        return _vm.upload(file, 'pinImage');
      }
    },
    model: {
      value: _vm.course.pinImage.name,
      callback: function ($$v) {
        _vm.$set(_vm.course.pinImage, "name", $$v);
      },
      expression: "course.pinImage.name"
    }
  })], 1)], 1)], 1)], 1), _vm.scope.includes('root') ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1 pb-0"
  }, [_vm._v("지점정보")]), _c('v-card-text', [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.course.waypoints.push({
          name: null,
          latitude: null,
          longitude: null
        });
      }
    }
  }, [_vm._v("지점추가")]), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.deleteWaypoint
    }
  }, [_vm._v("지점삭제")])], 1), _c('v-card-text', _vm._l(_vm.course.waypoints, function (waypoint, index) {
    return _c('v-row', {
      key: index,
      staticClass: "mt-3"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "이름",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.name,
        callback: function ($$v) {
          _vm.$set(waypoint, "name", $$v);
        },
        expression: "waypoint.name"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "위도",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.latitude,
        callback: function ($$v) {
          _vm.$set(waypoint, "latitude", $$v);
        },
        expression: "waypoint.latitude"
      }
    })], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": "경도",
        "persistent-placeholder": "",
        "hide-details": ""
      },
      model: {
        value: waypoint.longitude,
        callback: function ($$v) {
          _vm.$set(waypoint, "longitude", $$v);
        },
        expression: "waypoint.longitude"
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }