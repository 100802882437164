<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">코스 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <!-- <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[0]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-date-field v-model="filter.createdAt[1]" label="작성일자" persistent-placeholder hide-details></v-date-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <v-col cols="6" md="3" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" hide-details persistent-placeholder></v-select>
                            </v-col>
                            <v-col cols="6" md="3" class="py-0">
                                <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="(page = 1) && search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-data-table :items="courses" :headers="coursesHeaders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template #[`item.no`]="{ item }">
                    {{ (page - 1) * limit + (+courses.indexOf(item) + 1) }}
                </template>
                <template #[`item.createdAt`]="{ item }">
                    {{ item.createdAt.toDateTime() }}
                </template>
                <template #[`item.color`]="{item}">
                    <v-icon :style="`color: ${item.color.hex}`">mdi-palette</v-icon>
                </template>
                <template #[`item.waypoint`]="{item}">
                    {{ item._waypoint.length }} 개
                </template>
                <template #[`item.applicationCriteria`]="{item}">
                    {{ item.applicationCriteria }} 개
                </template>
                <template #[`item.actions`]="{item}">
                    <v-icon @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="scope.includes('root')" @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn v-if="scope.includes('root')" bottom color="accent" fab fixed right @click="create()">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        VDateField,
    },
    data() {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.query.code,
                category: this.$route.query.category,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                createdAt: [(this.$route.query.createdAt || [])[0], (this.$route.query.createdAt || [])[1]],
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            courses: [],
            coursesHeaders: [
                { text: "no", align: "center", width: 40, value: "no" },
                { text: "이름", align: "center", width: 250, value: "name" },
                { text: "지점", align: "center", width: 70, value: "waypoint" },
                { text: "응모기준", align: "center", width: 70, value: "applicationCriteria" },
                { text: "색상", align: "center", width: 70, value: "color" },
                { text: "작성일", align: "center", width: 70, value: "createdAt" },
                { text: "Actions", width: 40, align: "center", value: "actions" },
            ],

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "이름", value: "name" },
            ],
            searchKey: null,
            searchValue: null,
        };
    },
    mounted() {
        this.init();
    },
    watch: {
        $route() {
            this.search(false);

            Object.assign(this.filter, this.$route.query);
            this.page = +this.$route.query.page || 1;
        },
    },
    methods: {
        init() {
            this.search(false);
        },
        async search(routable = true) {
            try {
                if (routable) {
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page }),
                    });
                }

                var { summary, courses } = await api.console.courses.gets({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter,
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.courses = courses;
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create() {
            this.$router.push(`/console/courses/create`);
        },
        edit(course) {
            this.$router.push(`/console/courses/${course._id}`);
        },
        async remove(board) {
            try {
                if (confirm("게시글을 삭제하시겠습니까?")) {
                    await api.console.courses.delete(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        },
        scope() {
            return this.payload.scope || [];
        }
    },
};
</script>
