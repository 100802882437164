<template>
    <v-layout justify-center>
        <v-responsive max-width="1440" width="100%" height="100%">
        <v-row class="mt-0 mt-md-4 px-1" align="center">
            <v-col cols="auto" class="pt-0 pb-0 headline text-start">상품목록</v-col>
            <v-spacer/>
            <v-col cols="auto">
                <v-btn class="mr-1" color="secondary" rounded @click="showSearch = !showSearch">
                    <v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터
                </v-btn>
            </v-col>
        </v-row>

        <v-expand-transition>
            <v-card v-show="showSearch" class="elevation-1 mt-2 mx-1">
                <v-card-title class="tit tit--xs font-weight-bold">검색필터</v-card-title>
                <v-card-text class="pb-0">
                    <category-select v-model="filter._category" :categories="categories" @input="page = 1; search(true)" />
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.onDisplay" :items="displayItems" item-text="text" item-value="value" label="노출여부" persistent-placeholder hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" label="검색조건" persistent-placeholder hide-details @input="filter.searchValue = null;" />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-text-field v-model="filter.searchValue" label="검색어" persistent-placeholder hide-details clearable :disabled="!filter.searchKey" @keydown.enter="page = 1; search(true)" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-refresh</v-icon>초기화
                    </v-btn>
                    <v-btn color="primary" rounded text @click="page = 1; search(true)">
                        <v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-expand-transition>

        <v-data-table :items="products" item-key="_id" :headers="productsHeaders" hide-default-footer disable-sort disable-pagination class="mt-2 mx-1 elevation-1">
            <template #[`item.no`]="{ item }">
                {{ (page - 1) * limit + (+products.indexOf(item) + 1) }}
            </template>
            <template #[`item.price`]="{ item }">
                {{ item.price.format() }}원
            </template>
            <template #[`item.onDisplay`]="{ item }">
                <v-row justify="center" align="center">
                    <v-col cols="auto" class="pa-0">
                        <v-switch v-model="item.onDisplay" @change="update(item)"/>
                    </v-col>
                </v-row>
            </template>
            <v-img slot="item.thumb" slot-scope="{item}" :src="item.thumb.src" max-width="96" height="96" contain class="ma-auto"/>
            <span slot="item.createdAt" slot-scope="{item}">{{ $dayjs(item.createdAt).format("YYYY-MM-DD HH:mm:ss") }}</span>
            <span slot="item.actions" slot-scope="{item}">
                <v-icon class="mx-1" @click="edit(item)">mdi-pencil</v-icon>
                <!-- <v-icon class="mx-1" @click="remove(item)">mdi-delete</v-icon> -->
            </span>
        </v-data-table>

        <v-pagination v-model="page" :length="pageCount" total-visible="11" class="mt-3" @input="search(true)"/>

        <v-btn fab fixed bottom right color="accent" @click="create"><v-icon>mdi-pencil</v-icon></v-btn>
    </v-responsive>
    </v-layout>
</template>

<script>
import api from "@/api";
import CategorySelect from "@/components/console/shop/categories/category-select.vue";
import { mdiMicrosoftExcel } from "@mdi/js";

export default {
    components: {
        CategorySelect
    },
    data(){
        return {
            mdiMicrosoftExcel,

            showSearch: true,

            filter: {
                searchKey: this.$route.query.searchKey || null,
                searchValue: this.$route.query.searchValue || null,
                _category: null,
                onDisplay: null
            },

            categories: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            selected: [],
            summary: { totalCount: 0 },
            products: [],
            productsHeaders: [
                { text: "no", value: "no", align: "center", width: 50  },
                { text: "상품이름", value: "name", align: "center", width: 150 },
                { text: "썸네일", value: "thumb", align: "center", width: 150 },
                { text: "가격", value: "price", align: "center", width: 50  },
                { text: "노출여부", value: "onDisplay", align: "center", width: 100  },
                { text: "등록일자", value: "createdAt", align: "center", width: 180 },
                { text: "actions", value: "actions", align: "center", width: 100 },
            ],

            searchKeys: [
                { text: ":: 전체 ::", value: null },
                { text: "상품이름", value: "name" },
            ],
            displayItems: [
                { text: ":: 전체 ::", value: null },
                { text: "노출", value: true },
                { text: "미노출", value: false },
            ]
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            this.search(false);

            let { categories } = await api.console.shop.categories.gets({ params: { depth: 1 } });
            this.categories = categories;
        },
        async search(routable = false){
            if(routable){
                this.$router.push({
                    query: { ...this.filter, page: this.page }
                });
            }

            let { summary, products } = await api.console.shop.products.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit
                },
                params: this.filter
            });

            this.products = products;
            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
        },
        create(){
            this.$router.push(`/console/shop/products/create`);
        },
        edit(product){
            this.$router.push(`/console/shop/products/${product._id}`);
        },
        async update(product) {
            await api.console.shop.products.put(product);
            await this.init()
        },
        async remove(product){
            if(confirm("삭제하시겠습니까?")){
                await api.console.shop.products.delete(product);
                alert("삭제되었습니다");
                this.search(false);
            }
        }
    }
}
</script>